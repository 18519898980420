/*###########################################################################
// Business divisions stacker
//#########################################################################*/

import { debounce } from "@js/utilities/_throttleDebounce"
import { scrollTo } from "../utilities/_scrollTo"

const businessDivisionsStacker = {
  config: {
    businessDivisionsStacker: '.businessDivisionsStacker'
  },

  elements: {},

  init() {
    const self = this

    self.elements.businessDivisionsStackers = document.querySelectorAll(self.config.businessDivisionsStacker)

    if (self.elements.businessDivisionsStackers.length) {
      gsap.registerPlugin(ScrollTrigger)

      if (window.innerWidth >= 1024) {
        for (let i = 0; i < self.elements.businessDivisionsStackers.length; i++) {
          const businessDivisionsStacker = self.elements.businessDivisionsStackers[i]

          self.handleBusinessDivisionsStacker(businessDivisionsStacker)
        }
      }
    }
  },

  handleBusinessDivisionsStacker(businessDivisionsStacker) {
    const self = this

    const cards = businessDivisionsStacker.querySelectorAll('.cardDivisionLarge')

    // Global scroll trigger to ensure header stays hidden while card stacker in viewport
    //
    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: function() {
        if (ScrollTrigger.isInViewport(businessDivisionsStacker) && ScrollTrigger.positionInViewport(businessDivisionsStacker, "center").toFixed(2) < 1 && ScrollTrigger.positionInViewport(businessDivisionsStacker, "center").toFixed(2) > 0) {
          document.querySelector('.header').classList.add('header--hidden')
        } else {
          document.querySelector('.header').classList.remove('header--hidden')
        }
      }
    });

    let tl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: businessDivisionsStacker,
        ease: "power2.out",
        pin: true,
        scrub: 1,
        toggleActions:"restart none none reset",
      }
    });

    for (let i = 0; i < cards.length; i++) {
      const card = cards[i].parentNode
      const fakeButton = card.querySelector('.btn')

      if (i !== 0) {
        gsap.set(card, { y: i === 0 ? 0 : (i * 16) + "px", top: (window.innerHeight) + (16 * i), zIndex: 10 + (i+1), webkitFilter: "brightness(1)", filter: "brightness(0.25)" });

        tl.to(card, {
          duration: 1.5 + (i/20),
          top: 0,
          webkitFilter: "brightness(1)",
          filter: "brightness(1)",
          ease: "power2.out",
        }, "-=0.25").addLabel("card" + i)
      }

      card.addEventListener('click', function(e) {
        e.preventDefault()

        if (i !== 0) {
          gsap.to(window, {scrollTo: self.labelToScroll(tl, ("card" + i)) - 22, duration: 0.5})
        } else {
          gsap.to(window, {scrollTo: {
            y: tl.scrollTrigger.start
          }, duration: 0.5})
        }
      })

      fakeButton.addEventListener('click', function() {
        window.location = card.querySelector('a').getAttribute('href')
      })
    }
  },

  labelToScroll(timeline, label) {
    console.log('scroll');
    let st = timeline.scrollTrigger;
    return st.start + (st.end - st.start) * (timeline.labels[label] / timeline.duration());
  }
}

window.addEventListener('load', function () {
  setTimeout(function() {
    businessDivisionsStacker.init()
  }, 250)
})
