/*###########################################################################
// Stock Information 
//#########################################################################*/

export default function stockPriceFetcher() {
	return {
        price: "...",
		init() {
			this.fetchPrice(); // Fetch immediately on load
			setInterval(() => {
				this.fetchPrice(); // Re-fetch every 30 seconds
			}, 30000); // 30000 milliseconds = 30 seconds
		},

		fetchPrice() {
			fetch("https://irs.tools.investis.com/Clients/uk/brickabilitygroupplc/XML/xml.aspx?format=json")
            .then(response => response.json())
            .then((data) => {
				this.price = data.stockquotes.CurrentPrice;
			})
			.catch((error) => {
				console.error("Error fetching stock price:", error);
				this.price = "Failed to load price";
			});
		},
	};
}
