/*###########################################################################
// Scroll to
//#########################################################################*/

export const scrollTo = (element, offset = 0) => {
    const elementTop = element.getBoundingClientRect().top;
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollDistance = elementTop + scrollTop + offset;

    window.scrollTo({
        top: scrollDistance,
        left: 0,
        behavior: 'smooth'
    });
};