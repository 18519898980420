/*###########################################################################
// Header
//#########################################################################*/

import { throttle } from '../utilities/_throttleDebounce'

const header = {
    config: {
        main: 'main',
        header: '.header',
        headerBackdrop: '.header__backdrop',
        headerNav: '.header__nav',
        headerMenuBtn: '.header__menu-btn',
        headerSubMenu: '.header__submenu',
        headerSubMenuSingle: '.header__submenu--single',
        headerSubMenuTrigger: '.header__nav__item--sub',
        headerSubMenuBack: '.header__submenu__back',
    },

    elements: {
        main: null,
        header: null,
        headerBackdrop: null,
        headerNav: null,
        headerMenuBtn: null,
        headerSubMenus: null,
        headerSubMenuSingles: null,
        headerSubMenuTriggers: null
    },

    state: {
        isMobile: false,
        dropdownOpen: false
    },

    breakpoint: 1024,

    init() {
        const self = this

        self.elements.header = document.querySelector(self.config.header)

        if (self.elements.header !== null) {
            self.elements.main = document.querySelector(self.config.main)
            self.elements.headerBackdrop = self.elements.header.querySelector(self.config.headerBackdrop)
            self.elements.headerNav = self.elements.header.querySelector(self.config.headerNav)
            self.elements.headerMenuBtn = self.elements.header.querySelectorAll(self.config.headerMenuBtn)
            self.elements.headerSubMenus = self.elements.header.querySelectorAll(self.config.headerSubMenu)
            self.elements.headerSubMenuSingles = self.elements.header.querySelectorAll(self.config.headerSubMenuSingle)
            self.elements.headerSubMenuTriggers = self.elements.header.querySelectorAll(self.config.headerSubMenuTrigger)

            // Handle scroll
            //
            self.handleScroll()

            // Handle backdrop
            //
            self.handleBackdropClick()

            // Handle menu btn
            //
            self.handleMenuBtn()

            // Handle menu items
            //
            self.handleMenuItems()

            // Handle sub menu singles
            //
            self.handleSubMenuSingles()

            // Set nav height
            //
            if (window.innerWidth < self.breakpoint) {
                self.setNavHeight()

                self.state.isMobile = true
            } else {
                self.state.isMobile = false
            }

            window.addEventListener('resize', throttle(function() {
                if (window.innerWidth < self.breakpoint) {
                    self.setNavHeight()

                    self.state.isMobile = true
                } else {
                    self.unsetNavHeight()

                    if (self.state.isMobile === true) {
                        self.resetMenuState()
                    }

                    self.state.isMobile = false
                }

                self.handleSubMenuSingles()
            }, 250))
        }
    },

    handleScroll() {
        const self = this

        let scrollPosition = window.pageYOffset || document.documentElement.scrollTop

        // Initial check
        //
        if (scrollPosition > 20) {
            self.elements.main.classList.add('header--scrolled')
            self.elements.header.classList.add('header--scrolled')
        } else {
            self.elements.main.classList.remove('header--scrolled')
            self.elements.header.classList.remove('header--scrolled')
        }

        setTimeout(function() {
            // Set nav height
            //
            if (window.innerWidth < self.breakpoint) {
                self.setNavHeight()
            }
        }, 1000)

        let lastScrollTop = 0

        window.addEventListener('scroll', throttle(function() {
            scrollPosition = window.scrollY || document.documentElement.scrollTop

            if (scrollPosition > 5) {
                self.elements.main.classList.add('header--scrolled')
                self.elements.header.classList.add('header--scrolled')
            } else {
                self.elements.main.classList.remove('header--scrolled')
                self.elements.header.classList.remove('header--scrolled')
            }

            if (scrollPosition > 20 && window.innerWidth >= self.breakpoint) {
                if (lastScrollTop > scrollPosition) {
                    self.elements.header.classList.add('header--scrolling-up')
                } else {
                    self.elements.header.classList.remove('header--scrolling-up')
                }
            } else {
                self.elements.header.classList.remove('header--scrolling-up')
            }
            lastScrollTop = scrollPosition <= 0 ? 0 : scrollPosition

            setTimeout(function() {
                // Set nav height
                //
                if (window.innerWidth < self.breakpoint) {
                    self.setNavHeight()
                }
            }, 500)
        }, 250))
    },

    setNavHeight() {
        const self = this

        self.elements.headerNav.style.height = (window.innerHeight - (self.elements.header.clientHeight - 5)) + "px"

        for (let i = 0; i < self.elements.headerSubMenus.length; i++) {
            const headerSubMenu = self.elements.headerSubMenus[i]

            headerSubMenu.style.height = (window.innerHeight - (self.elements.header.clientHeight - 12)) + "px"
        }
    },

    unsetNavHeight() {
        const self = this

        self.elements.headerNav.style.height = ""

        for (let i = 0; i < self.elements.headerSubMenus.length; i++) {
            const headerSubMenu = self.elements.headerSubMenus[i]

            headerSubMenu.style.height = ""
        }
    },

    handleMenuBtn() {
        const self = this

        for (let i = 0; i < self.elements.headerMenuBtn.length; i++) {
            self.elements.headerMenuBtn[i].addEventListener('click', function(e) {
                e.preventDefault()

                if (self.elements.header.classList.contains('header--open')) {
                    self.elements.header.classList.remove('header--open')
                    self.elements.headerBackdrop.classList.remove('header__backdrop--active')
                    window.app.enableScroll()

                    // Reset sub menus
                    //
                    setTimeout(function() {
                        for (let i = 0; i < self.elements.headerSubMenuTriggers.length; i++) {
                            const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[i]
                            const headerSubMenu = headerSubMenuTrigger.nextElementSibling
                            headerSubMenu.classList.remove('header__submenu--open')
                            headerSubMenu.scrollTop = 0
                        }

                        // Remove potential classes
                        //
                        self.elements.header.classList.remove('header--sub-open')
                        self.elements.headerNav.classList.remove('overflow-hidden')

                        // Reset nav scroll
                        //
                        self.elements.headerNav.scrollTop = 0

                        // Reset nav overflow
                        //
                        self.elements.headerNav.style.overflowY = ''
                    }, 500)
                } else {
                    self.elements.header.classList.add('header--open')
                    self.elements.headerBackdrop.classList.add('header__backdrop--active')
                    window.app.disableScroll()
                }
            })
        }
    },

    resetMenuState() {
        const self = this

        self.elements.header.classList.remove('header--open')
        window.app.enableScroll()

        // Reset sub menus
        //
        setTimeout(function() {
            for (let i = 0; i < self.elements.headerSubMenuTriggers.length; i++) {
                const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[i]
                const headerSubMenu = headerSubMenuTrigger.nextElementSibling
                headerSubMenu.classList.remove('header__submenu--open')
                headerSubMenu.scrollTop = 0
                self.setSubMenuTabIndexes(headerSubMenu, false)
            }

            // Remove potential classes
            //
            self.elements.header.classList.remove('header--sub-open')
            self.elements.headerNav.classList.remove('overflow-hidden')

            // Reset nav scroll
            //
            self.elements.headerNav.scrollTop = 0

            // Reset dropdowns
            //
            if (self.elements.headerNav.querySelector('.header__nav__item--active') !== null) {
                self.elements.headerNav.querySelector('.header__nav__item--active').classList.remove('header__nav__item--active')
            }
            self.elements.headerBackdrop.classList.remove('header__backdrop--active')

            // Reset nav overflow
            self.elements.headerNav.style.overflowY = ''
        }, 500)
    },

    handleMenuItems() {
        const self = this

        for (let k = 0; k < self.elements.headerSubMenuTriggers.length; k++) {
            const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[k]

            if (headerSubMenuTrigger.nextElementSibling !== null) {
                const headerSubMenu = headerSubMenuTrigger.nextElementSibling
                const headerSubMenuBack = headerSubMenu.querySelector(self.config.headerSubMenuBack)

                headerSubMenuTrigger.addEventListener('keyup', function(e) {
                    if (e.key === 'Enter' || e.keyCode === 13) {
                        headerSubMenuTrigger.click()
                    }
                })

                headerSubMenuTrigger.addEventListener('click', function() {
                    // Handle mobile and desktop states
                    //
                    if (window.innerWidth < self.breakpoint) {
                        if (!headerSubMenu.classList.contains('header__submenu--open')) {
                            headerSubMenu.classList.add('header__submenu--open')
                            self.elements.header.classList.add('header--sub-open')
                            self.elements.headerNav.classList.add('overflow-hidden')
                            self.elements.headerNav.scrollTop = 0
                            headerSubMenu.scrollTop = 0

                            setTimeout(function() {
                                self.elements.headerNav.style.overflowY = 'hidden'
                            }, 500)
                        }
                    } else {
                        // Remove current active dropdown
                        //
                        if (headerSubMenuTrigger !== self.elements.headerNav.querySelector('.header__nav__item--active')) {
                            if (self.elements.headerNav.querySelector('.header__nav__item--active') !== null) {
                                self.elements.headerNav.querySelector('.header__nav__item--active').classList.remove('header__nav__item--active')
                                self.elements.headerBackdrop.classList.remove('header__backdrop--active')
                                self.setSubMenuTabIndexes(headerSubMenu, false)
                            }
                        }

                        // Toggle active dropdown
                        //
                        if (headerSubMenuTrigger.classList.contains('header__nav__item--active')) {
                            self.elements.header.classList.remove('header--sub-open')
                            headerSubMenuTrigger.classList.remove('header__nav__item--active')
                            self.elements.headerBackdrop.classList.remove('header__backdrop--active')
                            window.app.enableScroll()
                            self.setSubMenuTabIndexes(headerSubMenu, false)
                        } else {
                            self.elements.header.classList.add('header--sub-open')
                            headerSubMenuTrigger.classList.add('header__nav__item--active')
                            self.elements.headerBackdrop.classList.add('header__backdrop--active')
                            window.app.disableScroll()

                            // On opening a dropdown, ensure all submenu tab indexes are reset
                            //
                            for (let h = 0; h < self.elements.headerSubMenuTriggers.length; h++) {
                                const headerSubMenuTrigger = self.elements.headerSubMenuTriggers[h]

                                if (headerSubMenuTrigger.nextElementSibling !== null) {
                                    const headerSubMenu = headerSubMenuTrigger.nextElementSibling

                                    self.setSubMenuTabIndexes(headerSubMenu, false)
                                }
                            }

                            // Set tab indexes
                            //
                            self.setSubMenuTabIndexes(headerSubMenu, true)
                        }
                    }
                })

                if (headerSubMenuBack !== null) {
                    headerSubMenuBack.addEventListener('click', function() {
                        if (window.innerWidth < self.breakpoint) {
                            if (headerSubMenu.classList.contains('header__submenu--open')) {
                                headerSubMenu.classList.remove('header__submenu--open')
                                self.elements.header.classList.remove('header--sub-open')
                                self.elements.headerNav.classList.remove('overflow-hidden')
                                self.elements.headerNav.scrollTop = 0
                                headerSubMenu.scrollTop = 0

                                setTimeout(function() {
                                    self.elements.headerNav.style.overflowY = ''
                                }, 500)
                            }
                        }
                    })
                }
            }
        }
    },

    handleSubMenuSingles() {
        const self = this

        if (self.elements.headerSubMenuSingles !== null && window.innerWidth >= self.breakpoint) {
            for (let i = 0; i < self.elements.headerSubMenuSingles.length; i++) {
                const headerSubMenuSingle = self.elements.headerSubMenuSingles[i]
                const parentNavItem = headerSubMenuSingle.previousElementSibling
                const leftPosition = parentNavItem.getBoundingClientRect().left + (parentNavItem.getBoundingClientRect().width / 2)

                headerSubMenuSingle.style.left = leftPosition + 'px'
            }
        } else {
            for (let i = 0; i < self.elements.headerSubMenuSingles.length; i++) {
                const headerSubMenuSingle = self.elements.headerSubMenuSingles[i]
                headerSubMenuSingle.style.left = ''
            }
        }
    },

    setSubMenuTabIndexes(headerSubMenu, isFocusable) {
        const self = this

        const focusableElements = headerSubMenu.querySelectorAll('a, button')

        for (let i = 0; i < focusableElements.length; i++) {
            const focusableElement = focusableElements[i]

            if (isFocusable === true) {
                focusableElement.tabIndex = '0'
            } else {
                focusableElement.tabIndex = '-1'
            }
        }
    },

    handleBackdropClick() {
        const self = this

        self.elements.headerBackdrop.addEventListener('click', function() {
            if (self.elements.headerNav.querySelector('.header__nav__item--active') !== null && window.innerWidth > self.breakpoint) {
                self.elements.header.classList.remove('header--sub-open')
                self.elements.headerNav.querySelector('.header__nav__item--active').classList.remove('header__nav__item--active')
                self.elements.headerBackdrop.classList.remove('header__backdrop--active')
                window.app.enableScroll()
            }
        })
    }
}

window.addEventListener('load', function () {
    header.init()
})
