/*###########################################################################
// Formie custom
//#########################################################################*/

import { scrollTo } from "../utilities/_scrollTo"

const formieCustom = {
  config: {
    formieForm: '.formie-custom-form',
    formieSuccess: '.formie-custom-success',
  },

  elements: {
    formieForms: null
  },

  init() {
    const self = this

    self.elements.formieForms = document.querySelectorAll(self.config.formieForm)

    if (self.elements.formieForms !== null) {
      for (let i = 0; i < self.elements.formieForms.length; i++) {
        const formieFormContainer = self.elements.formieForms[i]

        self.handleFormieForm(formieFormContainer)
      }
    }
  },

  handleFormieForm(formieFormContainer) {
    const self = this

    const successEl = formieFormContainer.nextElementSibling
    self.handleFormSubmission(formieFormContainer, successEl)
  },

  handleFormSubmission(formieFormContainer, successEl) {
    const self = this

    formieFormContainer.querySelector('form').addEventListener("onAfterFormieSubmit", function (event) {
      formieFormContainer.classList.add('hidden')
      successEl.classList.remove('hidden')

      const offsetAmount = document.querySelector('.header').clientHeight + 40
      scrollTo(formieFormContainer.parentNode, -offsetAmount)
    })
  }
}

window.addEventListener('load', function () {
  formieCustom.init()
})
