/*###########################################################################
// Reults reports filter
//#########################################################################*/

import { scrollTo } from "../utilities/_scrollTo"

const resultsReportsFilter = {
  config: {
    paginationBtn: '.pagination .pagination__number, .pagination .pagination__arrow',
    filterBtn: '.pillBtn',
    filterSelect: '#yearSelected'
  },

  elements: {
    paginationBtns: null,
    filterBtns: null,
    filterSelect: null
  },

  init() {
    const self = this

    if (document.querySelector('#results-reports-container') !== null) {
      self.elements.paginationBtns = document.querySelectorAll(self.config.paginationBtn)

      if (self.elements.paginationBtns !== null) {
        // Handle pagination buttons
        //
        for (let i = 0; i < self.elements.paginationBtns.length; i++) {
          const paginationBtn = self.elements.paginationBtns[i]

          paginationBtn.addEventListener('click', function (event) {
            self.handlePaginationClick()
          })
        }

        if (typeof htmx !== 'undefined') {
          // Add loading state
          //
          htmx.on('htmx:beforeRequest', function (event) {
            document.querySelector('#results-reports-container').classList.add('is-loading')
            document.querySelector('#results-reports-container').style.pointerEvents = "none"
          })

          // After swap re-add event listeners for the above and remove loading state
          //
          htmx.on('htmx:afterSwap', function (event) {
            // Slight delay to ensure scroll has finished and indicate change
            setTimeout(function() {
              document.querySelector('#results-reports-container').classList.remove('is-loading')
              document.querySelector('#results-reports-container').style.pointerEvents = ""
            }, 1000)

            // Trigger a change event for sprig so it updates
            let swapEvent = new Event('filterSwap')
            window.dispatchEvent(swapEvent)

            setTimeout(function () {
              self.elements.paginationBtns = document.querySelectorAll(self.config.paginationBtn)

              for (let i = 0; i < self.elements.paginationBtns.length; i++) {
                const paginationBtn = self.elements.paginationBtns[i]

                paginationBtn.addEventListener('click', function (event) {
                  self.handlePaginationClick()
                })
              }
            }, 500)
          })
        }
      }

      // Handle filter select (mobile)
      //
      self.elements.filterSelect = document.querySelector(self.config.filterSelect)
      self.elements.filterSelect.addEventListener('change', function() {
        self.scrollToContainer(true)
      })

      self.elements.filterBtns = document.querySelectorAll(self.config.filterBtn)
      if (self.elements.filterBtns !== null) {
        for (let i = 0; i < self.elements.filterBtns.length; i++) {
          const filterBtn = self.elements.filterBtns[i]
          const selectToSync = document.querySelector(filterBtn.getAttribute('data-select'))

          filterBtn.addEventListener('click', function() {
            // Reset buttons
            for (let i = 0; i < self.elements.filterBtns.length; i++) {
              const filterBtn = self.elements.filterBtns[i]

              filterBtn.classList.remove('pillBtn--dark')
            }

            // Set current button
            filterBtn.classList.add('pillBtn--dark')

            selectToSync.value = filterBtn.getAttribute('data-value')

            // Trigger a change event for sprig so it updates
            let changeEvent = new Event('change')
            selectToSync.dispatchEvent(changeEvent)
          })
        }
      }
    }
  },

  handlePaginationClick() {
    const self = this

    // Scroll to entries
    self.scrollToContainer(false)
  },

  scrollToContainer(filterClick) {
    const self = this

    const offsetAmount = document.querySelector('.header').clientHeight + 40

    scrollTo(document.querySelector('#results-reports-container').parentNode, -offsetAmount)
  }
}

window.addEventListener('load', function () {
  resultsReportsFilter.init()
})
