/* CSS
//------------------*/

import "@js/css";

/* App
//------------------*/

window.app = {
  disableScroll() {
    document.documentElement.style.overflow = 'hidden'
  },

  enableScroll() {
    document.documentElement.style.overflow = ''
  }
}

/* Components
//------------------*/

import '@js/components/index'

/* Utilities
//------------------*/

import '@js/utilities/index'

/* Vendor
//------------------*/

import '@js/vendor/index'

/* Non-alpine JS */
//

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
*/
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import intersect from "@alpinejs/intersect";
//import alpineComponent from "@js/parts/alpineComponent";
import stockPriceFetcher from "./components/_stockInformation";

window.components = {
	stockPriceFetcher
};

window.Alpine = Alpine;

/* Register components */
//Alpine.data('alpineComponent', alpineComponent);
Alpine.plugin(collapse);
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
