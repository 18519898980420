/*###########################################################################
// Slider gallery
//#########################################################################*/

import Swiper from 'swiper';

const sliderGallery = {
  config: {
    sliderGallery: '.heroGallery'
  },

  elements: {},

  init() {
    const self = this;

    self.elements.sliderGalleries = document.querySelectorAll(self.config.sliderGallery);

    if (self.elements.sliderGalleries !== null) {
      for (let i = 0; i < self.elements.sliderGalleries.length; i++) {
        const sliderGallery = self.elements.sliderGalleries[i];

        self.handleSliderGallery(sliderGallery);
      }
    }
  },

  handleSliderGallery(slider) {
    const self = this;

    const prevBtn = slider.parentNode.querySelector('.slider__control--prev');
    const nextBtn = slider.parentNode.querySelector('.slider__control--next');

    const swiper = new Swiper(slider, {
      slidesPerView: "auto",
      spaceBetween: 30,
      autoHeight: true,
      loop:true,
      breakpoints: {
        // when window width is >= 640px
        640: {
          slidesPerView: 3,
          spaceBetween: 30, // Adjust the space between slides for desktop as needed
        },
      },
    });

    if (swiper.isBeginning) {
      prevBtn.classList.add('is-disabled');
    }

    swiper.on('slideChange', function() {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled');
      }

      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled');
      }
    });

    swiper.on('fromEdge', function() {
      prevBtn.classList.remove('is-disabled');
      nextBtn.classList.remove('is-disabled');
    });

    swiper.on('reachBeginning', function () {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled');
      }
    });

    swiper.on('reachEnd', function () {
      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled');
      }
    });

    prevBtn.addEventListener('click', function (e) {
      swiper.slidePrev();
    });

    nextBtn.addEventListener('click', function (e) {
      swiper.slideNext();
    });
  }
};

window.addEventListener('load', function () {
  sliderGallery.init();
});
