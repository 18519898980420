/*###########################################################################
// Scroll to
//#########################################################################*/

document.querySelectorAll('a[data-scroll-to]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault()

        let offsetAmount = document.querySelector('.header').clientHeight + 40
        const target = document.querySelector(this.getAttribute('href'))
        let targetPosition = 0

        if (target !== null) {
            targetPosition = window.pageYOffset + target.getBoundingClientRect().top;

            window.scrollTo({
                top: targetPosition - offsetAmount,
                behavior: 'smooth'
            })
        } else {
            console.error('Scroll to target not found.')
        }
    });
});
