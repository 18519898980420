/*###########################################################################
// Lazyload iframe
//#########################################################################*/

import { throttle } from "../utilities/_throttleDebounce"
import { inViewport } from "../utilities/_inViewport"

const lazyloadIframe = {
  config: {
    lazyloadIframe: '.iframe-lazyload'
  },

  elements: {},

  init () {
    const self = this

    self.elements.iframeLazyloads = document.querySelectorAll(self.config.lazyloadIframe)

    if (self.elements.iframeLazyloads !== null) {
      for (let i = 0; i < self.elements.iframeLazyloads.length; i++) {
        const iframeLazyload = self.elements.iframeLazyloads[i]

        self.handleLazyloadIframe(iframeLazyload)
      }
    }
  },

  handleLazyloadIframe(iframeLazyload) {
    const iframe = iframeLazyload.querySelector('iframe')

    if (inViewport(iframeLazyload, -(window.innerHeight/2)) && !iframeLazyload.classList.contains('iframe-lazyload--loaded')) {
      iframe.setAttribute('src', iframe.getAttribute('data-src'))
      iframeLazyload.classList.add('iframe-lazyload--loaded')
    }

    window.addEventListener('scroll', throttle(function() {
      if (inViewport(iframeLazyload, -(window.innerHeight/2)) && !iframeLazyload.classList.contains('iframe-lazyload--loaded')) {
        iframe.setAttribute('src', iframe.getAttribute('data-src'))
        iframeLazyload.classList.add('iframe-lazyload--loaded')
      }
    }, 200))
  }
}

window.addEventListener('load', function() {
  lazyloadIframe.init()
})
