/*###########################################################################
// Slider gallery
//#########################################################################*/

import Swiper from 'swiper';

const timelineSlider = {
    config: {
        timelineSliders: '.timelineSlider'
    },

    elements: {},

    init() {
        const self = this;

        self.elements.timelineSliders = document.querySelectorAll(self.config.timelineSliders);

        if (self.elements.timelineSliders !== null) {
            for (let i = 0; i < self.elements.timelineSliders.length; i++) {
                const timelineSliders = self.elements.timelineSliders[i];

                self.handleSliderGallery(timelineSliders);
            }
        }
    },

    handleSliderGallery(slider) {
        const self = this;

        const prevBtn = slider.parentNode.querySelector('.slider__control--prev');
        const nextBtn = slider.parentNode.querySelector('.slider__control--next');

        const swiper = new Swiper(slider, {
            slidesPerView: 1.2,
            spaceBetween: 30,
            loop: false,
            breakpoints: {
                // when window width is >= 640px
                640: {
                    slidesPerView: 2.5,
                    spaceBetween: 30, // Adjust the space between slides for desktop as needed
                },
                // when window width is >= 640px
                900: {
                    slidesPerView: 3,
                    spaceBetween: 30, // Adjust the space between slides for desktop as needed
                },
                // when window width is >= 640px
                1100: {
                    slidesPerView: 4,
                    spaceBetween: 30, // Adjust the space between slides for desktop as needed
                },
            },
            on: {
                slideChange: function () {
                    const activeSlideYear = this.slides[this.activeIndex].getAttribute('data-year-index');
                    const yearElements = document.querySelectorAll('[data-year]');

                    yearElements.forEach(element => {
                        if (element.getAttribute('data-year') === activeSlideYear) {
                            element.classList.add('active-timeline-item'); // Add your active class to the matching element

                             // Smoothly scroll to the active element
                            element.scrollIntoView({
                                behavior: 'smooth',
                                block: 'nearest', // Use 'start', 'center', or 'end' as needed
                                inline: 'nearest'
                            });
                        } else {
                            element.classList.remove('active-timeline-item'); // Remove the class from non-matching elements
                        }
                    });
                }
            }
        });

        document.querySelectorAll('[data-year]').forEach(element => {
            element.addEventListener('click', () => {
                const targetClass = element.getAttribute('data-year');
                console.log('.swiper-slide[data-year-index=' + targetClass + ']');
                const targetSlideElement = document.querySelector(`.swiper-slide[data-year-index='${targetClass}']`);
                console.log(targetSlideElement);
                if (targetSlideElement) {
                    const targetSlideIndex = swiper.slides.indexOf(targetSlideElement);
                    swiper.slideTo(targetSlideIndex);
                }
            });
        });

        if (swiper.isBeginning) {
            prevBtn.classList.add('is-disabled');
        }

        swiper.on('slideChange', function () {
            if (swiper.isBeginning) {
                prevBtn.classList.add('is-disabled');
            }

            if (swiper.isEnd) {
                nextBtn.classList.add('is-disabled');
            }
        });

        swiper.on('fromEdge', function () {
            prevBtn.classList.remove('is-disabled');
            nextBtn.classList.remove('is-disabled');
        });

        swiper.on('reachBeginning', function () {
            if (swiper.isBeginning) {
                prevBtn.classList.add('is-disabled');
            }
        });

        swiper.on('reachEnd', function () {
            if (swiper.isEnd) {
                nextBtn.classList.add('is-disabled');
            }
        });

        prevBtn.addEventListener('click', function (e) {
            swiper.slidePrev();
        });

        nextBtn.addEventListener('click', function (e) {
            swiper.slideNext();
        });
    }
};

window.addEventListener('load', function () {
    timelineSlider.init();
});
